<template>
  <div class="sellers-container">
    <div class="sellers">
      <div
        class="seller"
        v-for="seller in sellersStore.sellers"
        :key="seller.id"
      >
        <div class="name">{{ seller.name }}</div>
        <div class="points">{{ seller.votes * VOTE_POINT_COUNT }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSellersStore } from "@/stores/sellers";
import { VOTE_POINT_COUNT } from "@/constants/main";

const sellersStore = useSellersStore();
</script>

<style scoped>
.sellers-container {
  max-height: 300px;
  overflow: hidden;
  background-color: #70c5c3;
}

.sellers {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px clamp(0px, 2.5vw, 20px);
  flex-wrap: nowrap;
}

.seller {
  font-size: 12px;
  padding: 0 clamp(5px, 2.5vw, 15px);
  margin: 0 2px;
  display: flex;
  align-items: center;
}

.name {
  margin-bottom: 2px;
  margin-right: 5px;
}

.points {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #50a2a1;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 10px;
}

@media (min-width: 700px) {
  .sellers {
    justify-content: center;
  }
}
</style>
