import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { ROUTES } from "./routes";

const routes = [
  {
    path: ROUTES.HOME,
    name: "home",
    component: HomeView,
  },
  {
    path: ROUTES.SEARCH,
    name: "search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VoteView.vue"),
  },
  {
    path: ROUTES.WINNER,
    name: "winner",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WinnerView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
