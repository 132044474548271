<template>
  <div class="homeview-container">
    <div class="homeview-input-container">
      <form @submit.prevent="handleSubmit">
        <input
          required
          v-model="word"
          type="text"
          placeholder="Ejemplo: Manzana"
        />
      </form>
      <p class="label">Ingresa una palabra para consultar imagenes</p>
      <p v-if="hasError" class="input-error">
        Por favor ingresa una sola palabra valida que sea menor de 150
        caracteres
      </p>
    </div>
  </div>
</template>

<script setup>
import { MAX_WORD_LENGTH } from "@/constants/main";
import { ROUTES } from "@/router/routes";
import { useSellersStore } from "@/stores/sellers";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

const sellersStore = useSellersStore();

const word = ref("");
const hasError = ref(false);
const router = useRouter();

function handleSubmit() {
  if (!hasError.value) {
    router.push("/search/" + word.value);
  }
}

watch(word, (value) => {
  if (/\s/.test(value.trim()) || value.length > MAX_WORD_LENGTH) {
    hasError.value = true;
  } else {
    hasError.value = false;
  }
});

onMounted(() => {
  if (sellersStore.winner) {
    router.push(ROUTES.WINNER);
  }
});
</script>

<style scoped>
.homeview-container {
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 1;
}

.homeview-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 20px;
}

input {
  width: 250px;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
}
input:focus {
  outline: none;
}

.input-error {
  color: #c71b29;
  font-size: clamp(16px, 2.5vw, 24px);
}

p {
  color: white;
  font-size: clamp(16px, 2.5vw, 24px);
  margin-top: 0px;
}
</style>
