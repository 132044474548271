<template>
  <HeaderComponent />
  <div v-if="sellersStore.isLoading" class="spinner"></div>
  <div v-else-if="sellersError" class="error-message">Ups!, algo salio mal</div>
  <div v-else>
    <SellersPoints />
    <RouterView />
  </div>
</template>

<script setup>
import { RouterView } from "vue-router";
import { onMounted, ref } from "vue";
import { getSellers } from "@/services/alegra";
import { useSellersStore } from "@/stores/sellers";

import HeaderComponent from "@/components/HeaderComponent.vue";
import SellersPoints from "@/components/SellersPoints.vue";

const sellersStore = useSellersStore();
const sellersError = ref(false);

onMounted(() => {
  sellersStore.isLoading = true;

  getSellers()
    .then((sellersResponse) => {
      if (sellersResponse.data) {
        const sellers = sellersResponse.data.map((seller) => ({
          id: seller.id,
          name: seller.name,
          votes: 0,
        }));
        sellersStore.addSellers(sellers);
        sellersStore.isLoading = false;
      }
    })
    .catch(() => {
      sellersError.value = true;
      sellersStore.isLoading = false;
    });
});
</script>
