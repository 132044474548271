import axios from "axios";

const ALEGRA_API_BASE_URL = process.env.VUE_APP_ALEGRA_API_BASE_URL;
const ALEGRA_API_AUTH = process.env.VUE_APP_ALEGRA_API_AUTH;

const alegraApi = axios.create({
  baseURL: ALEGRA_API_BASE_URL,
  headers: {
    accept: "application/json",
    authorization: ALEGRA_API_AUTH,
  },
});

export const getSellers = () => alegraApi.get("/sellers");

export const getItems = () => alegraApi.get("/items");

export const createInvoice = (invoice) => alegraApi.post("/invoices", invoice);
