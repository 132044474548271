<template>
  <header class="header">
    <img
      alt="Imagenes del mundo logo"
      src="../assets/logo.png"
      class="logo-desktop"
    />
    <img
      alt="Imagenes del mundo logo"
      src="../assets/logo-mobile.png"
      class="logo-mobile"
    />
    <nav v-if="!(route.path === ROUTES.WINNER)">
      <router-link to="/" class="nav">Home</router-link>
    </nav>
  </header>
</template>

<script setup>
import { ROUTES } from "@/router/routes";
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<style scoped>
.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #d4f2f1;
  color: white;
}

nav a {
  font-weight: lighter;
  color: #005452;
  text-decoration: none;
  font-size: 24px;
}

.logo-desktop {
  display: block;
}

.logo-mobile {
  display: none;
}

@media (max-width: 900px) {
  .logo-desktop {
    display: none;
  }

  .logo-mobile {
    display: block;
  }
}
</style>
