import { WINNING_POINTS, VOTE_POINT_COUNT } from "@/constants/main";
import { defineStore } from "pinia";

export const useSellersStore = defineStore("sellers", {
  state: () => ({
    isLoading: false,
    sellers: [],
    winner: null,
  }),
  getters: {
    getSeller(state) {
      return (sellerId) =>
        state.sellers.find((seller) => seller.id === sellerId);
    },
    getSellerByIndex(state) {
      return (index) => state.sellers[index];
    },
  },
  actions: {
    addSellers(sellers) {
      this.sellers = sellers;
    },
    addSeller(seller) {
      this.sellers[seller.id] = seller;
    },
    vote(sellerId) {
      const sellerIndex = this.sellers.findIndex(
        (seller) => seller.id === sellerId
      );
      this.sellers[sellerIndex].votes++;
      if (
        this.sellers[sellerIndex].votes * VOTE_POINT_COUNT >=
        WINNING_POINTS
      ) {
        this.winner = this.sellers[sellerIndex];
      }
    },
    resetVotes() {
      this.sellers = this.sellers.map((seller) => ({ ...seller, votes: 0 }));
      this.winner = null;
    },
  },
});
